import 'sticky-kit/dist/sticky-kit';

(function() {
  const items = $('[data-sticky]');

  const initSticky = () => {
    items.each(function() {
      const el = $(this);
      el.stick_in_parent(el.data('sticky'));
    });
  };

  const destroySticky = () => {
    items.trigger('sticky_kit:detach');
  };

  const checkResolution = () => {
    if ($(window).width() > 1023) {
      initSticky();
    } else {
      destroySticky();
    }
  };

  $(window).resize(checkResolution);
  checkResolution();
})();
