import {
  methodOnItem,
  getItems,
  init,
} from './dom';

class Toggler {
  SETTINGS = {
    bodyClass: null,
    activeClass: 'active',
    outOff: true,
  };

  constructor(btn, options) {
    this.btn = btn;
    this.id = btn.getAttribute('data-toggler');

    this.SETTINGS = {
      ...this.SETTINGS,
      ...options,
      ...JSON.parse(this.btn.getAttribute('data-toggler-options')),
    };

    this.getItems();
    this.setEventListeners();
  }

  getItems() {
    this.contents = getItems(`[data-toggler-content=${this.id}]`);
  }

  outOffHandler(e) {
    const target = e.target;
    const isBtn = target.closest('[data-toggler]') === this.btn || target ===
      this.btn;
    const isContent = this.contents.includes(
      target.closest('[data-toggler-content]'));

    if (isBtn || isContent) return;

    this.closeContent();
  }

  setEventListeners() {
    this.btn.addEventListener('click', this.toggleContent);

    this.SETTINGS.outOff &&
    document.body.addEventListener('click', (e) => this.outOffHandler(e));
  }

  closeContent = () => {
    methodOnItem(this.contents,
      (item) => item.classList.remove(this.SETTINGS.activeClass));
    this.btn.classList.remove(this.SETTINGS.activeClass);
  };

  toggleContent = () => {
    methodOnItem(this.contents,
      (item) => item.classList.toggle(this.SETTINGS.activeClass));
    this.btn.classList.toggle(this.SETTINGS.activeClass);

    this.SETTINGS.bodyClass &&
    document.body.classList.toggle(this.SETTINGS.bodyClass);
  };
}

init('[data-toggler]', Toggler);
