import 'core-js//modules/es6.array.find'

//matches
(function (e) {
  const matches = e.matches || e.matchesSelector || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector || e.oMatchesSelector
  !matches ? (e.matches = e.matchesSelector = function matches(selector) {
    const matches = document.querySelectorAll(selector)
    const th = this
    return Array.prototype.some.call(matches, function (e) {
      return e === th
    })
  }) : (e.matches = e.matchesSelector = matches)
})(Element.prototype);

//closest
(function (e) {
  e.closest = e.closest || function (css) {
    let node = this

    while (node) {
      if (node.matches(css)) return node
      else node = node.parentElement
    }
    return null
  }
})(Element.prototype)

//pad
Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}
