import './polyfills'

import '@fancyapps/fancybox'
import 'jquery-mask-plugin'
import 'jq-accordion-responsive'

import '../blocks/header'
import '../blocks/map'
import '../blocks/staff-review'
import '../blocks/menu'

import './common/toggler'
import './common/sticky'
import './common/anchor'
import './common/slider'
