function init(selector, constructor, options) {
  const items = getItems(selector);

  return items.map(item => new constructor(item, options || {}));
}

function methodOnItem(collection, method) {
  return collection.map(item => method(item));
}

function getItems(selector) {
  return [].slice.call(document.querySelectorAll(selector));
}

function getChildren(parent, selector) {
  try {
    return [].slice.call(parent.querySelectorAll(selector));
  } catch (e) {
    console.warn(e.message);
    return [];
  }
}

function setEventListener(collection, event, method) {
  return collection.map(
    item => item.addEventListener(event, (e) => method(e, item)));
}

function getItemByAttrValue(collection, attr, value) {
  return collection.find(item => {
    const attrValue = item.getAttribute(attr);

    return attrValue === value;
  });
}

export {
  init,
  methodOnItem,
  getItems,
  getChildren,
  setEventListener,
  getItemByAttrValue,
};
