$('[data-menu]').each(function() {
  const control = $(this);
  const id = control.data('menu');
  const submenu = $(`[data-menu-submenu=${id}]`);

  function showSubmenu () {
    control.addClass('active');
    submenu.addClass('active');
  }

  function hideSubmenu () {
    control.removeClass('active');
    submenu.removeClass('active');
  }

  control.hover(showSubmenu, hideSubmenu);
  submenu.hover(showSubmenu, hideSubmenu);
});
