(function() {
  const header = $('[data-header]');
  let scrollTop = $(window).scrollTop();

  function checkPosition() {
    const windowScrollTop = $(window).scrollTop();

    if (windowScrollTop > 0) {
      header.addClass('_fixed');
    } else {
      header.removeClass('_fixed');
    }

    if (windowScrollTop > scrollTop) {
      header.addClass('_down');
    } else {
      header.removeClass('_down');
    }

    scrollTop = windowScrollTop;
  }

  $(window).scroll(checkPosition);
  checkPosition();
})();
