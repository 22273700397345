const CreateMap = function(map) {
    const SETTINGS = {
      hiddenClass: 'hidden',
      disableDefaultUI: true,
      zoomControl: true,
    };

    const NODES = {
      map,
    };

    const DATA = {
      map: null,
      markers: null,
      get icon() {
        return NODES.map.getAttribute('data-map-marker');
      },
      get zoom() {
        return Number(NODES.map.getAttribute('data-map-zoom')) || 15;
      },
      get center() {
        return NODES.map.getAttribute('data-map-center');
      },
      locations: [],
    };

    const METHODS = {
      init() {
        METHODS.getLocations();
      },
      getLocations() {
        try {
          DATA.locations = JSON.parse(map.getAttribute('data-map'));
          METHODS.createMap();
        } catch (e) {
          $.ajax(map.getAttribute('data-map')).then((resp) => {
            DATA.locations = resp;
            return METHODS.createMap();
          });
        }
      },
      createMap() {
        const center = DATA.center && DATA.center.split(',');

        DATA.map = new google.maps.Map(NODES.map, {
          zoom: DATA.zoom,
          center: center ? {
            lat: Number(center[0]),
            lng: Number(center[1]),
          } : DATA.locations[0],
          zoomControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP,
          },
          ...SETTINGS,
        });

        METHODS.createMarkers();
      },

      createMarkers() {
        DATA.markers = DATA.locations.map((location) => {
          const {price} = location;

          const marker = new google.maps.Marker({
            position: location,
            map: DATA.map,
            icon: DATA.icon,
          });

          if (price) {
            const infowindow = new google.maps.InfoWindow({
              content: `${price.toString()}₽`,
            });

            infowindow.open(DATA.map, marker);
          }

          return marker;
        });
      },

      $: function(selector) {
        return [].slice.call(document.querySelectorAll(selector));
      },

      eventForNodes: function(nodes, callback) {
        nodes.forEach(function(node) {
          callback(node);
        });
      },
    };

    METHODS.init();
  }

;(function(key) {
  const mapWraps = [].slice.call(document.querySelectorAll('[data-map]'));
  let scriptInPage = false;

  if (mapWraps.length > 0) {
    const scripts = [].slice.call(document.querySelectorAll('script'));

    scripts.forEach(function(item) {
      const src = item.getAttribute('src');

      if (src && src.indexOf('maps.googleapis.com/maps/api') !== -1) {
        scriptInPage = true;
      }
    });

    if (scriptInPage) {
      mapWraps.forEach(function(wrap) {
        CreateMap(wrap);
      });
    } else {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + key;
      document.body.appendChild(script);

      script.onload = function() {
        mapWraps.forEach(function(wrap) {
          CreateMap(wrap);
        });
      };
    }
  }
})('AIzaSyDsD6ymenJTiBxQiKvfWZ30n16TsOOeO3Q');
