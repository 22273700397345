import 'slick-carousel'

$('[data-slider]').slick({
  slidesToShow: 1,
  dots: true,
  autoplay: true
});

$('[data-slider-diff]').slick({
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$('[data-slider-certs]').slick({
  slidesToShow: 6,
});

$('[data-slider-news]').slick({
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 830,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});
