$('[data-review]').each(function() {
  const text = $(this);
  const textHeight = text.innerHeight();
  const lineHeight = parseInt(text.css('line-height'));
  const initialHeight = lineHeight * 2;

  if (textHeight > initialHeight) {
    let isActive = false;
    const togglerText = text.data('review').split('|');

    function toggleHandler() {
      text.stop().animate({
        height: isActive ? initialHeight : textHeight,
      }, 300);

      if (isActive) {
        text.removeClass('active');
        $(this).removeClass('active');
        $(this).text(togglerText[0]);
      } else {
        text.addClass('active');
        $(this).addClass('active');
        $(this).text(togglerText[1]);
      }

      isActive = !isActive;
    }

    text.css({
      height: initialHeight,
    });

    text.after(
      '<button class="staff-review__toggler">' + togglerText[0] + '</button>',
    );

    const toggler = text.next('button');
    toggler.bind('click', toggleHandler);
  }
});
