$('[data-anchor]').click(function(e) {
  e.preventDefault();
  const id = $(this).attr('href');

  $('body, html').stop().animate({
    scrollTop: $(id).length > 0 ?
      $(id).offset().top - $(this).parent().innerHeight() :
      0,
  }, 400);
});
